import React, { useEffect, useState } from "react"
import MainLayout from "../components/layouts/main-layout.js"
import { Link } from "gatsby"
import Seo from "../components/seo.js"

import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Banner from "../components/hero/banner-hometeam.js"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import HomeHero from "../components/hero/hero-software.js"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"
import { useContext } from "react"

import SAM from "../components/new-quandary/software-asset-mgmt.js"
import ItConsulting from "../components/sam.js"
import StepProcess from "../components/new-quandary/4-steps-process-new.js"
import Testimonials2 from "../components/swiper/testimonial-swiper.js"
import PartnerSwiper from "../components/swiper/swiper-partner.js"
import Cases from "../components/cases.js"
import UnlockEnterprise from "../components/unlock-enterprise.js"
import CaseStudySection from "../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto"></div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

const SamPage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }
  }, [])
  const { data } = props
  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      <Seo
        title="Software Asset Management | Quandary Consulting Group"
        description="Our Denver-based team provides custom integrations and workflow mapping that drives continuous process improvement for mid-market and enterprise."
      />

      <HomeHero />
      <PartnerSwiper />
      <div id="it-consult" className="it-consult-scroll" />
      <ItConsulting />
      <section
        className="faq-wrapper security-audit"
        style={{ padding: "1.5rem 0 4.5rem" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <h2 className="mt-0 text-center">
                Our 4-Step Process: Done in Weeks
              </h2>
              <Accordion defaultActiveKey="0" key="1">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="1" className="bg-transparent">
                    1. Discovery
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <strong>Weeks 1-2</strong>
                      <p>
                        We audit your tech stack and interview your team to
                        assess needs, goals, and pain points against industry
                        benchmarks.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="2">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="2" className="bg-transparent">
                    2. Audit
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <strong>Weeks 3-4</strong>
                      <p>
                        We run five reports: Software Utilization, Staffing
                        Optimization, Cost-Benefit Analysis, Integration
                        Assessment, and Security Review.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="3">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="3" className="bg-transparent">
                    3. Delivery
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <strong>Weeks 5</strong>
                      <p>
                        We present detailed recommendations, including tool
                        consolidation, contract renegotiation, and more.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="0" key="4">
                <Card className="accordion-body">
                  <ContextAwareToggle eventKey="4" className="bg-transparent">
                    4. Implementation
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <strong>Weeks 6-7</strong>
                      <p>
                        We support implementation by guiding your team or acting
                        as an extension of it, using our agile approach.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Testimonials2 />
      <CaseStudySection />
      <CaseComponent dataSrc={data.allSanityCaseStudy} />
      <Banner />
      <UnlockEnterprise />
      <section
        className="bg-case-study slogan-section"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center">
              <div>
                <h2 className="text-white">Where Does Your IT Budget Go?</h2>
                {/* <p className="text-white">
                  Our 10-point security audit will show you how secure your
                  applications are. <br /> Protect your data. Protect your
                  investment. Protect your organization.
                </p> */}

                <Link
                  to="/contact/"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                  style={{ marginTop: "auto" }}
                >
                  Let's Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default SamPage

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

export const softwareAssetMgmtPageQuery = graphql`
  query softwareAssetMgmtPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
