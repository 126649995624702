import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../hooks/useMediaQuery"
import marked from "marked"

const Sam = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const data = [
    {
      title: "Low-Code Custom Application Developement",
      description:
        "<p>Get your team out of the spreadsheets and in front of customers with low-code applications that streamline operations, improve customer service, and provide clean data for AI-driven insights.</p>",
      url: "/custom-quickbase-application-development/",
      imgUrl: "/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg",
      direction: "ltr",
    },
    {
      title: "Integrate and Automate Workflows",
      description:
        "<p>Eliminate manual data entry by connecting your disparate systems. Accelerate your ROI with reduced labor costs, fewer mistakes, and real-time data insights.</p>",
      url: "/quickbase-integration-services/",
      imgUrl: "/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570",
      direction: "rtl",
    },
  ]
  // Build
  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Simplify Software. Cut Costs. Eliminate redundancies.
            </h2>
            <p
              className="text-center mx-auto px-sm-2"
              style={{ maxWidth: "825px" }}
            >
              We analyze your tech stack, consolidating your licenses and IT
              expenses. (<i>No spreadsheets required.</i>)
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label for="tab1" class="tabs__label mx-auto">
                Maximize Your Software Budget
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/18f241e7f548edce060f6b465c35e081fdd1f77e-1920x1280.jpg"
                      alt="Maximize Your Software Budget"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Maximize Your Software Budget
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Unused tools Cut IT waste by 30%, giving your team more
                      resources for high-value projects.
                    </p>
                  </div>
                </div>
              </div>

              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label for="tab2" class="tabs__label mx-auto">
                For SMBs with Complex Systems
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      For SMBs with Complex Systems
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Bespoke service: optimize your tech stack at the
                      department, processes, and culture level.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/b11289351072390d64089a1cfb0cf320b8998845-1920x1280.jpg"
                      alt="For SMBs with Complex Systems"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label for="tab3" class="tabs__label mx-auto">
                Reclaim Your Time and Drive Business Growth
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/6a81ae67bb231367001416d2c3af32e3c0db1cc9-1920x1442.jpg"
                      alt="Reclaim Your Time and Drive Business Growth"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Reclaim Your Time and Drive Business Growth
                    </h3>
                    <p className="my-4 text-left-mobile">
                      We implement in weeks using low-cost, low-risk tools,
                      focusing on high-impact changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Maximize Your Software Budget</h3>
                    <p className="my-4">
                      Unused tools Cut IT waste by 30%, giving your team more
                      resources for high-value projects.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/18f241e7f548edce060f6b465c35e081fdd1f77e-1920x1280.jpg"
                      alt="Maximize Your Software Budget"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">For SMBs with Complex Systems</h3>
                    <p className="my-4">
                      Bespoke service: optimize your tech stack at the
                      department, processes, and culture level.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/b11289351072390d64089a1cfb0cf320b8998845-1920x1280.jpg"
                      alt="For SMBs with Complex Systems"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>

              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">
                      Reclaim Your Time and Drive Business Growth
                    </h3>
                    <p className="my-4">
                      We implement in weeks using low-cost, low-risk tools,
                      focusing on high-impact changes.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/6a81ae67bb231367001416d2c3af32e3c0db1cc9-1920x1442.jpg"
                      alt="Reclaim Your Time and Drive Business Growth"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default Sam
