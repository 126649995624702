import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import { Link } from "gatsby"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../../hooks/useMediaQuery"

const StepProcessNew = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const data = [
    {
      title: "Low-Code Custom Application Developement",
      description:
        "<p>Get your team out of the spreadsheets and in front of customers with low-code applications that streamline operations, improve customer service, and provide clean data for AI-driven insights.</p>",
      url: "/custom-quickbase-application-development/",
      imgUrl: "/dceec435af07d66ca7a1aa259a5bdfc91ea427ff-1280x960.jpg",
      direction: "ltr",
    },
    {
      title: "Integrate and Automate Workflows",
      description:
        "<p>Eliminate manual data entry by connecting your disparate systems. Accelerate your ROI with reduced labor costs, fewer mistakes, and real-time data insights.</p>",
      url: "/quickbase-integration-services/",
      imgUrl: "/d59e61ab873e78f8a1ee6e84ea00ce619c06e0c2-1280x959.jpg?w=570",
      direction: "rtl",
    },
  ]

  return (
    <section id="it-consulting" className="it-consulting">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto text-center">
            <StaticImage
              placeholder="blurred"
              className="logo-static-img mt-2 mb-3"
              imgClassName="img-fluid w-100 h-100"
              src="../../images/new-images/process-timeline.png"
              alt="search"
              layout="constrained"
              loading="lazy"
            />
          </div>
          {/* <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-2 font-weight-medium text-left-mobile">
              Enterprise-Level Solutions - Built in Weeks (Not Months)
            </h2>
            <p
              className="text-left-mobile mx-auto"
              style={{ maxWidth: "630px" }}
            >
              So you can connect your systems and automate processes with clean
              data by levering the latest AI enabled platforms.
            </p>
          </div> */}
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5 pb-5">
                <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                  Discovery
                </h3>
                <h4 className="text-left-mobile p-0 m-0">Weeks 1-2</h4>
                <p className="my-4 text-left-mobile">
                  We audit your tech stack and interview your team to assess
                  needs, goals, and pain points against industry benchmarks.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 mt-4 mb-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/130e96066663555aeacca1f82a602989e4ff2c8d-3000x2000.jpg"
                  alt="Discovery"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div>

            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/afdb38d302dc7e29d0890d78e0c4fa84d6289d08-3000x2000.jpg"
                  alt="Audit"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                  Audit
                </h3>
                <h4 className="text-left-mobile p-0 m-0">Weeks 3-4</h4>
                <p className="my-4 text-left-mobile">
                  We run five reports: Software Utilization, Staffing
                  Optimization, Cost-Benefit Analysis, Integration Assessment,
                  and Security Review.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                  Delivery
                </h3>
                <h4 className="text-left-mobile p-0 m-0">Weeks 5</h4>
                <p className="my-4 text-left-mobile">
                  We present detailed recommendations, including tool
                  consolidation, contract renegotiation, and more.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 mt-4 mb-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/30f04d04c0d54ee2717db9e5e46133bdb6867298-3000x2000.jpg"
                  alt="Delivery"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
            </div>

            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-5 mb-5"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 mt-lg-4 mb-lg-4">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="https://cdn.sanity.io/images/55lcecww/production/2692a2a84c361735887b358d5af2f8f1bda19b96-3000x2000.jpg"
                  alt="Implementation"
                  layout="constrained"
                  style={{ height: "20rem" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center pl-md-5">
                <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                  Implementation
                </h3>
                <h4 className="text-left-mobile p-0 m-0">Weeks 6-7</h4>
                <p className="my-4 text-left-mobile">
                  We support implementation by guiding your team or acting as an
                  extension of it, using our agile approach.
                </p>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper">
            <LazyLoadComponent>
              <Swiper {...params}>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      {/* <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex w-100"
                  src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
                  alt="Application Development"
                  layout="constrained"
                  loading="lazy"
                  fetchpriority="low"
                /> */}
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/130e96066663555aeacca1f82a602989e4ff2c8d-3000x2000.jpg"
                        alt="Discovery"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                        Discovery
                      </h3>
                      <h4 className="text-left-mobile p-0 m-0">Weeks 1-2</h4>
                      <p className="my-4 text-left-mobile">
                        We audit your tech stack and interview your team to
                        assess needs, goals, and pain points against industry
                        benchmarks.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/afdb38d302dc7e29d0890d78e0c4fa84d6289d08-3000x2000.jpg"
                        alt="Audit"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                        Audit
                      </h3>
                      <h4 className="text-left-mobile p-0 m-0">Weeks 3-4</h4>
                      <p className="my-4 text-left-mobile">
                        We run five reports: Software Utilization, Staffing
                        Optimization, Cost-Benefit Analysis, Integration
                        Assessment, and Security Review.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/30f04d04c0d54ee2717db9e5e46133bdb6867298-3000x2000.jpg"
                        alt="Delivery"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                        Delivery
                      </h3>
                      <h4 className="text-left-mobile p-0 m-0">Weeks 5</h4>
                      <p className="my-4 text-left-mobile">
                        We present detailed recommendations, including tool
                        consolidation, contract renegotiation, and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div>
                    <div className="col-12">
                      <img
                        placeholder="blurred"
                        className="rounded d-flex w-100 img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/2692a2a84c361735887b358d5af2f8f1bda19b96-3000x2000.jpg"
                        alt="Implementation"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="text-left-mobile text-cyan font-weight-medium text-uppercase">
                        Implementation
                      </h3>
                      <h4 className="text-left-mobile p-0 m-0">Weeks 6-7</h4>
                      <p className="my-4 text-left-mobile">
                        We support implementation by guiding your team or acting
                        as an extension of it, using our agile approach.
                      </p>
                    </div>
                  </div>
                </div>
              </Swiper>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default StepProcessNew
